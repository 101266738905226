module.exports = {
  accounts: require("./accounts.json"),
  asns: require("./asns.json"),
  common: require("./common.json"),
  countries: require("./countries.json"),
  inventory: require("./inventory.json"),
  locations: require("./locations.json"),
  navigation: require("./navigation.json"),
  orders: require("./orders.json"),
  printConfigs: require("./printConfigs.json"),
  rmas: require("./rmas.json"),
  shipping: require("./shipping.json"),
  shippingMethods: require("./shippingMethods.json"),
  skus: require("./skus.json"),
  statuses: require("./statuses.json"),
  tasks: require("./tasks.json"),
  facilities: require("./facilities.json"),
  zones: require("./zones.json"),
  containers: require("./containers.json"),
  equipments: require("./equipments.json"),
  mhComponents: require("./mhComponents.json"),
  reports: require("./reports.json"),
}
